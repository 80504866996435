<div class="invoice-list">
  <div
    class="header-container d-flex justify-content-space-between align-items-end title-container mb-20"
  >
    @if (hasInvoiceViewPermission) {
      <div class="d-flex justify-content-start align-items-center">
        <p-button
          type="button"
          label="Proposals"
          [outlined]="isInvoice"
          styleClass="proposal-button"
          (onClick)="setTableConfig('proposal')"
          (keyup.enter)="setTableConfig('proposal')"
          tabindex="0"
        >
          <img src="assets/icons/file-text{{ isInvoice ? '' : '-white' }}.svg" alt="" srcset="" />
        </p-button>
        <p-button
          type="button"
          label="Invoices"
          [outlined]="!isInvoice"
          icon="pi pi-money-bill"
          [styleClass]="isInvoice ? 'active-button' : 'invoice-button'"
          (onClick)="setTableConfig('invoice')"
          (keyup.enter)="setTableConfig('invoice')"
          tabindex="0"
        />
      </div>
    }
    <div>
      @if ((hasCreateProposalPermission || hasCreateInvoicePermission) && !isGlobalInvoiceList) {
        <p-button
          class="primary-color add-proposal"
          type="button"
          class="add-proposal"
          icon="pi pi-plus"
          label="Add Proposal"
          [outlined]="true"
          size="small"
          (onClick)="navigateCreateInvoice()"
          (keyup.enter)="navigateCreateInvoice()"
          tabindex="0"
        />
      }
    </div>
  </div>

  @if (hasInvoiceViewPermission) {
    @if (dynamicTableConfig) {
      <app-table [tableConfig]="dynamicTableConfig" (tableSignals)="handleTableSignals($event)">
        <ng-template #noRecordFoundTemplate>
          <div class="container">
            <div class="image-container">
              <img [src]="emptyStateImage" alt="" />
            </div>
            <span class="empty-state-title">{{ emptyStateTitle }}</span>
            <span class="empty-state-description">{{ emptyStateDescription }}</span>
            @if (
              (hasCreateProposalPermission || hasCreateInvoicePermission) &&
              !isInvoice &&
              !isGlobalInvoiceList
            ) {
              <div class="box">
                <p-button
                  class="primary-color add-task"
                  type="button"
                  class="add-task"
                  icon="pi pi-plus"
                  label="Add Proposal"
                  [outlined]="false"
                  size="small"
                  (onClick)="navigateCreateInvoice()"
                />
              </div>
            }
          </div>
        </ng-template>
      </app-table>
    }
  }
</div>
