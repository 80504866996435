import { DataTableConfig, TableActions } from '../shared/interfaces/table';
import { AllGroupPermissions } from '@shared/enums/all-group-permissions/permissions';
import { PrimeIcons } from 'primeng/api';
import { InvoiceDetailResponse } from '@shared/interfaces/invoice.interface';
import { GlobalInvoiceTableConfig } from './globalInvoicesTableConfig.const';

export const GlobalProposalTableConfig: DataTableConfig<InvoiceDetailResponse> = {
  endPoint: 'assetmanagement/invoices/',
  showCheckboxes: true,
  showActions: true,
  additionalParams: [
    {
      key: 'is_invoice',
      value: false,
    },
  ],
  actionItems: [
    {
      value: TableActions.openEditMode,
      label: 'Edit',
      icon: PrimeIcons.PENCIL,
      disabled: false,
      permission: AllGroupPermissions.CHANGE_PROPOSAL,
    },
    {
      value: TableActions.deleteItem,
      label: 'Delete',
      icon: PrimeIcons.TRASH,
      disabled: false,
      permission: AllGroupPermissions.DELETE_INVOICE,
    },
  ],
  sortBy: 'name',
  formatData: GlobalInvoiceTableConfig.formatData,
  columns: [...GlobalInvoiceTableConfig.columns],
};
