import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalInvoiceTableConfig } from '@constants/globalInvoicesTableConfig.const';
import { GlobalProposalTableConfig } from '@constants/globalProposalsTableConfig.const';
import { InvoiceTableConfig } from '@constants/invoicesTableConfig.const';
import { ProposalTableConfig } from '@constants/proposalsTableConfig.const';
import { DeleteDialogService } from '@Services/dialogs/delete-dialog/delete-dialog.service';
import { InvoiceService } from '@Services/invoices/invoice.service';
import { PermissionService } from '@Services/permission/permission.service';
import { ToastMessageService } from '@Services/toast-message/toast-message.service';
import { PageTitleComponent } from '@shared/components/page-title/page-title.component';
import { TableComponent } from '@shared/components/table/table.component';
import { AllGroupPermissions } from '@shared/enums/all-group-permissions/permissions';
import { InvoiceStatusName } from '@shared/enums/invoice/invoice-status';
import { InvoiceDetailResponse } from '@shared/interfaces/invoice.interface';
import { DataTableConfig, TableActions, TableSignals } from '@shared/interfaces/table';
import { get } from 'lodash';
import { ButtonModule } from 'primeng/button';
import { TabViewModule } from 'primeng/tabview';
import { noop } from 'rxjs';

@Component({
  selector: 'app-invoice-listing',
  standalone: true,
  imports: [PageTitleComponent, ButtonModule, TableComponent, CommonModule, TabViewModule],
  templateUrl: './invoice-listing.component.html',
  styleUrl: './invoice-listing.component.scss',
})
/**
 * Represents the component for displaying a list of invoices.
 */
export class InvoiceListingComponent implements OnInit {
  @Input() isGlobalInvoiceList = false;
  projectId: string | null = null;

  isInvoice = false;

  @Input() dynamicTableConfig: DataTableConfig<InvoiceDetailResponse> | null = null;

  @ViewChild(TableComponent, { static: false }) invoiceTable?: TableComponent<unknown>;

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly permissionService: PermissionService,
    private readonly deleteDialogService: DeleteDialogService,
    private readonly invoiceService: InvoiceService,
    private readonly toastMessageService: ToastMessageService,
  ) {}

  ngOnInit(): void {
    if (!this.isGlobalInvoiceList) {
      this.projectId = get(this.activatedRoute.parent?.snapshot, 'params.id');
      this.setTableConfig();
    }
  }

  setTableConfig(type = 'proposal') {
    switch (type) {
      case 'proposal':
        this.isInvoice = false;
        if (this.isGlobalInvoiceList) {
          this.dynamicTableConfig = GlobalProposalTableConfig;
          return;
        }
        this.dynamicTableConfig = {
          ...ProposalTableConfig,
          endPoint: `assetmanagement/projects/${this.projectId}/invoices/`,
        };
        break;

      case 'invoice':
        this.isInvoice = true;
        if (this.isGlobalInvoiceList) {
          this.dynamicTableConfig = GlobalInvoiceTableConfig;
          return;
        }
        this.dynamicTableConfig = {
          ...InvoiceTableConfig,
          endPoint: `assetmanagement/projects/${this.projectId}/invoices/`,
        };
        break;

      default:
        this.isInvoice = false;
        this.dynamicTableConfig = ProposalTableConfig;
    }
  }

  /**
   * Navigates to the create invoice page.
   */
  navigateCreateInvoice(): void {
    // navigate to create invoice page
    this.router.navigate([`/project/${this.projectId}/invoice/create`]);
  }

  /**
   * Handles the signals emitted by the invoice table.
   * @param event - The table signals event.
   */
  handleTableSignals(event: TableSignals<InvoiceDetailResponse>) {
    if (this.isGlobalInvoiceList) {
      this.handleGlobalTableSignals(event);
      return;
    }
    const data = event.data;
    if (event.action === TableActions.openEditMode) {
      this.router.navigate([`edit/${data.id}`], { relativeTo: this.activatedRoute });
    } else if (event.action === TableActions.viewProjectDetails) {
      this.router.navigate([`details/${data.id}`], { relativeTo: this.activatedRoute });
    } else if (event.action === TableActions.deleteItem) {
      this.openInvoiceDeleteDialog(data);
    } else if (event.action === TableActions.exportToPDF) {
      this.exportToPDF(data.id);
    }
  }

  handleGlobalTableSignals(event: TableSignals<InvoiceDetailResponse>) {
    const data = event.data;
    this.projectId = data.project?.id as string;
    if (event.action === TableActions.openEditMode) {
      this.router.navigateByUrl(`/project/${data.project?.id}/invoice/edit/${data.id}`);
    } else if (event.action === TableActions.viewProjectDetails) {
      this.router.navigateByUrl(`/project/${data.project?.id}/invoice/details/${data.id}`);
    } else if (event.action === TableActions.deleteItem) {
      this.openInvoiceDeleteDialog(data);
    } else if (event.action === TableActions.exportToPDF) {
      this.exportToPDF(data.id);
    }
  }

  openInvoiceDeleteDialog(invoiceDetails: InvoiceDetailResponse) {
    let type = 'proposal';
    if (invoiceDetails?.invoice_status === InvoiceStatusName.APPROVED) {
      type = 'invoice';
    }
    this.deleteDialogService.openDeleteDialog(
      {
        headerText: `Delete ${type}`,
        name: invoiceDetails?.code,
        description: `Please provide a reason for deleting this ${type}`,
        path: `assetmanagement/projects/${this.projectId}/invoices/${invoiceDetails?.id}/`,
      },
      type,
      () => {
        this.invoiceTable?.reloadCurrentPage();
      },
    );
  }

  exportToPDF(invoiceId: string) {
    this.invoiceService
      .downloadInvoice(this.projectId as string, invoiceId)
      .then(noop)
      .catch((httpError: HttpErrorResponse) => {
        this.toastMessageService.showValidationErrors(
          httpError.error,
          httpError?.error?.error_message ?? 'Unable to download invoice.',
        );
      });
  }

  /**
   * Checks if the user has permission to create a proposal.
   * @returns True if the user has permission, false otherwise.
   */
  get hasCreateProposalPermission(): boolean {
    return this.permissionService.hasPermission(AllGroupPermissions.CREATE_PROPOSAL);
  }

  /**
   * Checks if the user has permission to create an invoice.
   * @returns True if the user has permission, false otherwise.
   */
  get hasCreateInvoicePermission(): boolean {
    return this.permissionService.hasPermission(AllGroupPermissions.ADD_INVOICE);
  }

  /**
   * Checks if the user has permission to view invoices.
   * @returns True if the user has permission, false otherwise.
   */
  get hasInvoiceViewPermission(): boolean {
    return this.permissionService.hasPermission(AllGroupPermissions.VIEW_INVOICE);
  }

  /**
   * Gets the image path for the empty state based on the current view.
   * @returns The image path.
   */
  get emptyStateImage(): string {
    return this.isInvoice ? 'assets/icons/money-bill.svg' : 'assets/icons/file-text.svg';
  }

  /**
   * Gets the title for the empty state based on the current view.
   * @returns The empty state title.
   */
  get emptyStateTitle(): string {
    return this.isInvoice ? 'No Invoices' : 'No Proposals';
  }

  /**
   * Gets the description for the empty state based on the current view.
   * @returns The empty state description.
   */
  get emptyStateDescription(): string {
    if (this.invoiceTable?.filtersData) {
      return 'No search results found with these filters.';
    } else {
      return this.isInvoice
        ? 'No proposals have been approved as invoices yet.'
        : 'There are no proposals drafted.';
    }
  }

  get filtersData() {
    return this.invoiceTable?.filtersData;
  }
}
